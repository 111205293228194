// Entry point for the build script in your package.json

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@hotwired/turbo-rails")
import "./helpers"
import "chartkick/chart.js"
require("prismjs")
require('prismjs/components/prism-bash')
require('prismjs/components/prism-javascript')
require('prismjs/components/prism-ruby')
require('prismjs/components/prism-python')
require('prismjs/components/prism-go')
require('prismjs/components/prism-json')
require('prismjs/components/prism-markup')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// import "../css/application.css"
// import "controllers"
import "./controllers"

import 'prismjs/themes/prism-tomorrow.css';
import 'font-awesome/css/font-awesome.min.css';