import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    // Add a click handler to remove this when clicking the header above
    const emailId = this.element.dataset.emailId;
    const emailHeader = document.getElementById(emailId);

    const clickHandler = (event) => {
      event.preventDefault();
      this.element.remove();
      emailHeader.removeEventListener('click', clickHandler);
    }

    emailHeader.addEventListener('click', clickHandler);

    var tabButtons = this.element.querySelectorAll('.email-tab-btn ');
    var tabContents = this.element.querySelectorAll('.email-tab-content');

    tabButtons.forEach((button, index) => {
        button.addEventListener('click', () => {
            // Make others inactive
            tabButtons.forEach(btn => {
              btn.classList.remove('font-semibold', 'border-blue-500')
              btn.classList.add('hover:text-gray-700', 'border-transparent')
            });
            tabContents.forEach(content => content.classList.add('hidden'));

            // Make clicked one active
            button.classList.add('font-semibold', 'border-blue-500')
            button.classList.remove('hover:text-gray-700', 'border-transparent');
            tabContents[index].classList.remove('hidden');
        });
    });
  }
}