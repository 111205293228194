import ClipboardJS from "clipboard"
import { Notyf } from 'notyf';
import Prism from 'prismjs'
import 'notyf/notyf.min.css';

window.addEventListener('turbo:load', (event) => {

    // Fix for turbo stopping Prism from running
    Prism.highlightAll();

    const notyf = new Notyf({
        "layout": "topRight",
        "timeout": "4000"
    });
    const clipboard = new ClipboardJS('.copy-button');

    clipboard.on('success', function(e) {
        notyf.success('Copied to clipboard');
        e.clearSelection();
    });

    const button = document.getElementById('verify-btn');
    const advButton = document.getElementById('adv-verify-btn');

    const text = button ? button.text : 'Verify domain'
    const advText = advButton ? advButton.text : 'Verify Additional Records'

    function showMessage(message) {
        message[0] === 'success' ? notyf.success(message[1]) : notyf.error(message[1]);
    }

    function verificationSuccess() {
        button.text = "Domain Verified";
        button.classList.add("btn-success", "disabled");
        button.classList.remove("btn-primary");
        showMessage(["success", "Successfully verified domain"])
        document.getElementById('next-btn').classList.remove("hidden");
    }

    function advVerificationSuccess() {
        advButton.text = "Domain Verified";
        advButton.classList.add("btn-success", "disabled");
        advButton.classList.remove("btn-primary");
        showMessage(["success", "Advanced verification complete!"])
    }

    function verificationFailure() {
        button.text = text;
        showMessage(["fail", "No matching DKIM record found, please check your DNS records and try again"])
    }

    function advVerificationFailure() {
        advButton.text = advText;
        showMessage(["fail", "No matching CNAME or TXT record found"])
    }

    function sent() {
        button.text = "Verifying domain..."
    }

    function advSent() {
        advButton.text = "Verifying..."
    }

    if (button) {
        button.addEventListener("ajax:success", verificationSuccess)
        button.addEventListener("ajax:error", verificationFailure)
        button.addEventListener("ajax:send", sent)
    }

    if (advButton) {
        advButton.addEventListener("ajax:success", advVerificationSuccess)
        advButton.addEventListener("ajax:error", advVerificationFailure)
        advButton.addEventListener("ajax:send", advSent)
    }
});
