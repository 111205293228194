import { Controller } from "@hotwired/stimulus";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

export default class extends Controller {
    connect() {
        const notyf = new Notyf({
            "duration": "4000"
        });

        // these come from the HTML attributes
        // e.g. dataFlashType == <html data-flash-type="abc" />
        let type = this.element.dataset.flashType;
        let message = this.element.dataset.flashMessage;

        // Create notyf flash messages
        notyf[type](message);
    }
}
