import Prism from 'prismjs'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["iframe", "htmlbody"]

  accordionTargetConnected(element) {
    // Add click event listener for email content accordions
    element.querySelectorAll('.accordion-header').forEach(header => {
      header.addEventListener('click', () => {
        this.toggleAccordion(header);
      });
    });
  }

  htmlbodyTargetConnected(element) {
    Prism.highlightAllUnder(element);
  }

  desktop() {
    this.setSize("100%", "800px")
  }

  tablet() {
    this.setSize("800px", "800px")
  }

  mobile() {
    this.setSize("400px", "500px")
  }

  setSize(width, height) {
    this.iframeTarget.style.width = width
    this.iframeTarget.style.height = height
  }

  toggleAccordion(header) {
    const content = header.nextElementSibling;
    content.classList.toggle('hidden');
  }

}
