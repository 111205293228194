import { Controller } from "stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["email", "response", "box"]

  initialize() {
    this.search = debounce(this.search, 500).bind(this)
  }

  emailTargetConnected(element) {
    Prism.highlightAllUnder(element);
  }

  responseTargetConnected(element) {
    Prism.highlightAllUnder(element);
  }

  search(element) {
    element.target.form.requestSubmit();
  }
}
