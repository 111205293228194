import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

// Connects to data-controller="blocked-address"
export default class extends Controller {
  initialize() {
    this.search = debounce(this.search, 500).bind(this)
  }

  search(element) {
    element.target.form.requestSubmit();
  }
}
