import { Controller } from "stimulus"
import { debounce } from "lodash"
export default class extends Controller {
  initialize() {
    this.search = debounce(this.search, 500).bind(this)
  }

  search(element) {
    element.target.form.requestSubmit();
  }

  change_tag() {
    const tag = encodeURIComponent(this.element.tag.value);
    Chartkick.eachChart( function(chart) {
      const url = `${chart.getDataSource().split('?')[0]}?tag=${tag}`
      chart.updateData(url);
    });
    this.element.requestSubmit();
  }
}